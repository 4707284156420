<template>
  <div v-if="item" class="page-content">
    <page-breadcrumb
      title="Landing Page Detail"
      class="mb-2"
      :items="breadcrumbItems"
    />
    <h2><b>Landing Page</b></h2>
    <form-generator :model="item" :schema="item_form_schema" />
    <b-card>
      <h3 class="mb-2">Anchor zone</h3>
      <form-generator :model="item" :schema="item_form_schema_anchor" />
      <h4>Products</h4>
      <app-collapse
        v-if="item.products.length"
        type="shadow"
        class="my-2 border p-1"
      >
        <app-collapse-item
          v-for="(product, index) in item.products"
          :key="product.id || product._id"
          :title="`${product.store_id || `Product ${index + 1}`}`"
        >
          <template slot="header">
            <h4>{{ product.store_id || `Product ${index + 1}` }}</h4>
            <div style="position: absolute; right: 30px; ">
              <b-button
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click.stop="removeItem(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
          <form-generator :model="product" :schema="product_form_schema" />
        </app-collapse-item>
      </app-collapse>
      <div class="text-center">
        <b-button variant="success" @click="addProduct">+ Add Product</b-button>
      </div>
    </b-card>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from "../service";
import productDefault from "../productDefaultObject";
const item_form_schema = [
  {
    cols: 12,
    fields: [
      {
        label: "Market",
        field: "market",
        input_type: "language",
        validate: { required: true }
      },
      {
        label: "Layout",
        field: "layout",
        input_type: "select",
        options: [
          { text: "Layout 1", value: 1 },
          { text: "Layout 2", value: 2 },
          { text: "Layout 3", value: 3 },
          { text: "Layout 4", value: 4 },
          { text: "Layout 5", value: 5 },
          { text: "Layout 6", value: 6 },
          { text: "Layout 7", value: 7 }
        ],
        validate: { required: true }
      },
      { label: "Landing page key", field: "key", validate: { required: true } },
      { label: "Title", field: "title", input_type: "ui-component" },
      { label: "Subtitle", field: "subtitle", input_type: "ui-component" },
      { label: "Mask Background", field: "mask_background" },
      { label: "Landing page background", field: "background" },
      {
        label: "Landing page html code",
        field: "html_code",
        input_type: "ui-component"
      }
    ]
  }
];
const item_form_schema_anchor = [
  {
    cols: 12,
    fields: [
      {
        label: "Enable Countdown",
        field: "countdown_enable",
        input_type: "switch"
      },
      {
        label: "Enable Bottom Zone",
        field: "enable_bottom_zone",
        input_type: "switch"
      },
      { label: "Countdown Font Color", field: "countdown_font_color" },
      { label: "Countdown Background Color", field: "countdown_bg_color" },
      { label: "Bottom Background Color", field: "bottom_bg_color" },
      {
        label: "Discount Info",
        field: "discount_info",
        input_type: "ui-component"
      },
      {
        label: "Discount Background URL",
        field: "discount_bg",
        input_type: "textarea"
      },
      {
        label: "Original Price",
        field: "price_origin",
        input_type: "textarea"
      },
      { label: "Sale Price", field: "price_sale", input_type: "textarea" },
      {
        label: "Store Product ID",
        field: "store_product_id",
        input_type: "textarea"
      },
      { label: "Info Text", field: "info_text", input_type: "ui-component" },
      {
        label: "Button Text",
        field: "button_text",
        input_type: "ui-component"
      },
      {
        label: "Button Redirect",
        field: "button_redirect",
        input_type: "select",
        options: [
          { text: "App Store Payment Pop-up", value: 1 },
          { text: "Sale Pop-up", value: 2 },
          { text: "Landing Page", value: 3 },
          { text: "IAP Pop-up", value: 4 },
          { text: "Trigger", value: 5 }
        ]
      },
      { label: "Button Redirect Page", field: "button_redirect_page" },
      {
        label: "Subscription Group Identifier",
        field: "subscription_group_identifier"
      },
      {
        label: "Close button Text",
        field: "close_button_text",
        input_type: "ui-component"
      }
    ]
  }
];
const product_form_schema = [
  {
    cols: 12,
    fields: [
      {
        label: "Store Product ID",
        field: "store_product_id",
        validate: { required: true }
      },
      { label: "Tag", field: "tag", input_type: "ui-component" },
      { label: "Name", field: "name", input_type: "ui-component" },
      {
        label: "Description",
        field: "description",
        input_type: "ui-component"
      },
      { label: "Discount", field: "discount" },
      {
        label: "Discount Info",
        field: "discount_info",
        input_type: "ui-component"
      },
      { label: "Discount Background URL", field: "discount_background" },
      {
        label: "Original Price",
        field: "original_price"
      },
      { label: "Sale Price", field: "sale_price" },
      { label: "Default", field: "default", input_type: "switch" }
    ]
  }
];
export default {
  components: {},
  data() {
    return {
      item: null,
      item_form_schema,
      item_form_schema_anchor,
      isUpdating: false,
      isDeleting: false,
      product_form_schema,
      productDefault
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Landing Page",
          to: { name: "landing-page-list" }
        },
        {
          text: `${this.item ? this.item.title : this.itemId}`,
          active: true
        }
      ];
      return items;
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.item = await service.getDetail({ id: this.itemId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item)
      });
      this.getDetail();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "landing-page-list" });
    },
    addProduct() {
      this.item.products.push(JSON.parse(JSON.stringify(productDefault)));
    },
    removeItem(index) {
      this.item.products.splice(index, 1);
    }
  }
};
</script>
